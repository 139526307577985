/* eslint-disable react-hooks/exhaustive-deps */

import styled from "styled-components";

import { BoxContainer } from "./Common";
import { useGame } from "../contexts";

import { digit } from "../utility";
import {GameStates} from "../utility/game"
import {useState} from "react"

const Container = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  background-color: white;
  min-width: 10vw;
  cursor: move;
  h1 {
    font-size: 1.2rem;
  }
`;



export const TimerBox = ({ minutes, seconds }) => {
  const { currentPlayer, updatePlayer, yourTurn,updateRoom } = useGame();  
  const [show, setShow] = useState(false)
  
  const increaePassiveIncome = async () => {    
    const passiveIncome = currentPlayer.passiveIncome + 1000
    const res= await updatePlayer({ cash:1000000, winner: true});
    updateRoom({
      gameState: GameStates.winner,
      turnData: null,
      deal: null,
      offer: null,
      doodad: null,
    });
    
  };
  return (
    <Container>
      <BoxContainer style={{ color: "green", padding: "1px" }}>
        {show && <button onClick={increaePassiveIncome}>lose BUTTON</button>}
        <h1>
          {digit(minutes)}:{digit(seconds)}
        </h1>

      </BoxContainer>
    </Container>
  );
};
