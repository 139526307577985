/* eslint-disable react-hooks/exhaustive-deps */
import { CustomModal } from "./Modal";
import { Button } from "antd";
import { useGame } from "../contexts";
import { BoxContainer, ButtonRow } from "./Common";
//import { useTimer } from "react-timer-hook";
import { useMemo, useState, useCallback } from "react";
import { useAuth } from "../contexts/auth";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

export const TurnBox = ({ roll, pay }) => {
  const { currentPlayer, yourTurn, getNextTurnUser, kickOutUser, players } =
    useGame();
  const [isTurnExpired] = useState(false);
  const { user } = useAuth();

  const { currentGamePlayers, loggedInPlayer } = useMemo(
    () => ({
      loggedInPlayer: players.find((x) => x.id === user?.uid),
      currentGamePlayers: players.filter((x) => !x.lose && !x.left),
    }),
    [players, user],
  );

  const nextUser = useMemo(() => getNextTurnUser(), [currentPlayer]);
  const [showKickOut] = useState(false);

  const rollCall = useCallback((count) => {
    roll(count);
  },[yourTurn,currentPlayer]);

  const onRoll = useMemo(()=> debounce(rollCall,500),[rollCall])

  return (
    <CustomModal>
      <BoxContainer>
        {loggedInPlayer?.left ? (
          <>
            <h2>{loggedInPlayer?.name} LOSE!</h2>
            <p>You have been kicked out from the game</p>
            <Link to="/rooms">
              <Button type="primary" block>
                Play Again
              </Button>
            </Link>
          </>
        ) : (
          <>
            {isTurnExpired && yourTurn ? (
              <div></div>
            ) : (
              <div>
                <h2>{currentPlayer?.name}'s Turn</h2>
                <h3>When you are ready, roll the die and take your turn</h3>
                <p>
                  Before you start your turn, review your financial statement.
                  {!currentPlayer?.fastTrack &&
                    <span>You may also use this time to repay liabilities or borrow money.</span>}
                </p>
              </div>
            )}
            {yourTurn && !isTurnExpired && (
              <div>
                {currentGamePlayers.length > 1 ? (
                  <ButtonRow>
                    <Button type="primary" block onClick={() => onRoll(1)}>
                      Roll
                    </Button>
                    {currentGamePlayers.charityTurns > 0 && (
                      <Button type="primary" block onClick={() => onRoll(2)}>
                        Roll 2X
                      </Button>
                    )}
                  </ButtonRow>
                ) : (
                  <ButtonRow>
                    <Button type="primary" block onClick={() => onRoll(1)}>
                      Roll
                    </Button>
                    {currentGamePlayers.charityTurns > 0 && (
                      <Button type="primary" block onClick={() => onRoll(2)}>
                        Roll 2X
                      </Button>
                    )}
                  </ButtonRow>
                )}
              </div>
            )}
            {showKickOut &&
              user?.uid === nextUser.id &&
              user?.uid !== currentPlayer.id && (
                <div>
                  <p>
                    Player is taking too much time to play turn, you can kick
                    out if you want
                  </p>
                  <Button
                    type="primary"
                    block
                    onClick={() => kickOutUser(currentPlayer)}
                  >
                    Kick out
                  </Button>
                </div>
              )}
          </>
        )}
      </BoxContainer>
    </CustomModal>
  );
};
