
import { useGame } from "../contexts";
import { Button } from "antd";
import { BoxContainer, ButtonRow } from "./Common";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import Draggable from "react-draggable";
import styled from "styled-components";

const Container = styled.div`
  z-index: 2;
  min-height: 250px;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-color: white;
  max-width: 30vw;
  cursor: move;
  h1 {
    font-size: 1.2rem;
  }
`;



export const LoseModal = ({ children }) => {
  return (
    <Draggable>
      <Container>{children}</Container>
    </Draggable>
  );
};


export const LoseBox = ({loggedInPlayer}) => {
  const { yourTurn, currentPlayer,nextTurn, players} = useGame();
  useEffect(()=>{
    setTimeout(()=>{
      if (yourTurn && players.length > 1) {
        nextTurn()
      }
    },5000)

  },[currentPlayer,yourTurn])


  return (
    <LoseModal>
      <BoxContainer>
        <h2>{loggedInPlayer?.lose ? loggedInPlayer?.name : currentPlayer?.name} LOSE!</h2>
        <p>
          You are unable to afford your debt. You are officially out of the
          game.
        </p>

        {(yourTurn || loggedInPlayer?.lose) && (
          <ButtonRow>
            <Link to="/rooms">
              <Button type="primary" block>
                Play Again
              </Button>
            </Link>
          </ButtonRow>
        )}
      </BoxContainer>
    </LoseModal>
  );
};
