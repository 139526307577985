import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import styled from "styled-components";

const MobileScreen = styled.div`
  display: none;
  @media screen and (max-width: 1025px) {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  h5 {
    font-size: 22px;
    text-align: center;
    line-height: 34px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;
  }
`;
const DesktopScreen = styled.div`
  display: block;
  @media screen and (max-width: 1025px) {
    display: none;
  }
`;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <DesktopScreen>
      <App />
    </DesktopScreen>
    <MobileScreen>
      <h5>Please use a desktop computer instead of a mobile device</h5>
    </MobileScreen>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
