
import { useCallback } from "react";
import { debounce } from "lodash";
import { CustomModal } from "./Modal";
import { Button } from "antd";
import { useGame } from "../contexts";
import { BoxContainer, ButtonRow } from "./Common";
import { AudioConstant } from "../audio";
import { playAudio } from "../utility";
import { GameStates} from "../utility/game";




export const FinishTurnBox = () => {
  const { repay, borrow, yourTurn, nextTurn, currentPlayer, updatePlayer, updateRoom } = useGame();

  const onEndTurn = () => {    
    playAudio(AudioConstant.human,currentPlayer);
    nextTurn();
  }
  const endTurnClick = useCallback(debounce(onEndTurn,500),[]) 
  const borrowClick = useCallback(debounce(borrow,500),[])
  const repayClick = useCallback(debounce(repay,500),[])

  return (
    <CustomModal>
      <BoxContainer>
        <div>
          <h2>FINISH {yourTurn ? "YOU" : `${currentPlayer?.name}`} TURN</h2>
          <p>
            Before you end your turn, review your financial statement. 
            {!currentPlayer?.fastTrack && <span>You may
            also use this time to repay liabilities or borrow money.</span>}
          </p>
        </div>
        {yourTurn && (
          <>
            {!currentPlayer?.fastTrack && 
            <ButtonRow>
              <Button onClick={() => repayClick()} block type="primary">
                Repay
              </Button>
              <Button type="primary" block onClick={() => borrowClick()}>
                Borrow
              </Button>
            </ButtonRow>}
            <div>
              <Button
                type="primary"
                block
                onClick={() => {
                  endTurnClick()
                }}
              >
                End Turn
              </Button>
            </div>
          </>
        )}
      </BoxContainer>
    </CustomModal>
  );
};
