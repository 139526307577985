export class DataClass {
  static dreams = [];
  static cards = {};
  static scenarios = {};

  static setData = (data) => {
    if (data?.dreams) {
      DataClass.dreams = data?.dreams;
    }
    if (data?.scenarios) {
      DataClass.scenarios = data?.scenarios || {};
    }
    if (data?.cards) {     
      DataClass.cards = data?.cards || {};
    }
  };
}
