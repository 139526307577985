import { Alert, Button, Form } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { FormTextFormField } from "../elements";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { CommonConstant, FirebaseService } from "../utility";
import { useState } from "react";

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100vh;
`;

// Apply custom styles to input fields
const InputContainer = styled.div`
  width: 100%;
  max-width: 390px;
`;

export const AuthBottomLink = styled.p`
  font-weight: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.colorWhite};
  font-size: 12px;
  margin-left: 90px;
`;

// const AlertContainer = styled(Alert)`
//   margin-bottom: 1rem;
// `;

const ForgotSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email address is required")
    .matches(CommonConstant.emailRegex, "Invalid email address"),
});

export const ForgotScreen = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotSchema),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const submit = async (formData) => {
    try {
      setLoading(true);

      const responseData = await fetchSignInMethodsForEmail(
        FirebaseService.auth,
        formData.email,
      );

      if (responseData.length > 0) {
        await sendPasswordResetEmail(FirebaseService.auth, formData.email);
        toast.success("Password reset email sent. Check your inbox.");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.error("user with this email not exist.");
      }
    } catch (error) {
      setError("Invalid email address");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(submit)}>
        <CenteredDiv>
          <h1>Forgot Your Password?</h1>

          <InputContainer>
            <FormTextFormField
              control={control}
              name="email"
              placeholder="Your Email Address"
              errors={errors?.email}
              defaultValue=""
              label="Email address"
              required
            />

            {error && <Alert message={error} type="error" showIcon />}
            <Button type="primary" block htmlType="submit" loading={loading}>
              Submit
            </Button>
          </InputContainer>
        </CenteredDiv>
      </Form>
    </>
  );
};
