import { CustomModal } from "./Modal";
import { Button, InputNumber } from "antd";
import { LiabilityConstant, currencyFormat, playAudio } from "../utility";
import { BoxContainer, ButtonRow } from "./Common";
import { useMemo, useState } from "react";
import { useGame } from "../contexts";
import { AudioConstant } from "../audio";
import { styled } from "styled-components";

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-group-addon {
    padding-left: 0px;
    padding-right: 0px;
    button {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }
`;

export const RepayBox = () => {
  const { selectedRow, finishTurn, currentPlayer, yourTurn, payoffLoan } =
    useGame();

  const [customValue, setCustomValue] = useState(1000);
  const showConfirm = useMemo(() => {
    if (selectedRow?.key === LiabilityConstant.loans) {
      return true;
    }
    return currentPlayer?.cash > selectedRow?.value;
  }, [selectedRow, currentPlayer]);

  const maxValue = useMemo(() => {
    return currentPlayer?.cash < selectedRow?.value ? currentPlayer?.cash : selectedRow?.value
  },[selectedRow, currentPlayer, customValue])

  const disabledConfirm = useMemo(() => {
    if (selectedRow?.key === LiabilityConstant.loans) {
      return customValue > currentPlayer.cash;
    }
    return false;
  }, [selectedRow, currentPlayer, customValue]);
  

  return (
    <CustomModal>
      <BoxContainer>
        <h2>PAY OFF LOAN</h2>
        {selectedRow?.id ? (
          <>
            <p>
              Pay off {selectedRow.name} for {currencyFormat(selectedRow.value)}
            </p>
            {selectedRow.key === LiabilityConstant.loans ? (
              <>
                <p>
                  Input an amount to repay your loan Payments must be in
                  multiples of $1,000
                </p>
                <div>
                  <StyledInputNumber
                    addonBefore={
                      <button
                        onClick={() => customValue < maxValue && setCustomValue(customValue + 1000)}
                      >
                        +
                      </button>
                    }
                    addonAfter={
                      <button
                        onClick={() =>
                          customValue >= 2000 &&
                          setCustomValue(customValue - 1000)
                        }
                      >
                        -
                      </button>
                    }
                    controls={false}
                    min={1000}
                    max={maxValue}
                    value={customValue}
                    onChange={setCustomValue}
                  />
                </div>
              </>
            ) : (
              <>
                {showConfirm ? (
                  <p>Are you sure?</p>
                ) : (
                  <p>You do not have enough cash to pay off this loan</p>
                )}
              </>
            )}
          </>
        ) : (
          <p>Select a liability on your statement sheet to repay your debt.</p>
        )}

        {yourTurn && (
          <ButtonRow>
            {showConfirm && (
              <Button
                type="primary"
                block
                disabled={disabledConfirm}
                onClick={() =>
                  !disabledConfirm &&
                  payoffLoan(
                    selectedRow,
                    selectedRow?.key === LiabilityConstant.loans
                      ? customValue
                      : null
                  )
                }
              >
                Confirm
              </Button>
            )}
            <Button
              danger
              type="primary"
              block
              onClick={() => {
                playAudio(AudioConstant.human,currentPlayer);
                finishTurn();
              }}
            >
              Cancel
            </Button>
          </ButtonRow>
        )}
      </BoxContainer>
    </CustomModal>
  );
};
